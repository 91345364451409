import moment from 'moment-timezone'
import { fvpRatesService } from '../services'
import formatter from './formatter'

moment.tz.setDefault('Australia/Melbourne')

export default class DurationBreakdown {
  constructor (funderId, holiday, nextHoliday, isSleepover) {
    this.funderId = funderId
    this.holiday = holiday
    this.nextHoliday = nextHoliday
    this.isSleepover = isSleepover
  }

  async get (std, etd) {
    const body = {
      funder_id: this.funderId,
      date: std
    }

    const rateSet = await fvpRatesService.getDetails(body)

    let normalHourStart = rateSet.normal_hours_start
    let normalHourEnd = rateSet.normal_hours_end
    let afterHourStart = rateSet.after_hours_start
    let afterHourEnd = rateSet.after_hours_end

    const startDate = moment.isMoment(std) ? std.clone() : moment(std)
    const endDate = moment.isMoment(etd) ? etd.clone() : moment(etd)
    const startDateDay = startDate.clone().format('dddd')
    const startDateDayNumber = this.getDayInNumber(startDateDay)
    const endDateDay = endDate.clone().format('dddd')
    const endDateDayNumber = this.getDayInNumber(endDateDay)

    const jobStartTime = moment(startDate.clone().format('hh:mm a'), 'hh:mm a')
    const jobEndTime = moment(endDate.clone().format('hh:mm a'), 'hh:mm a')
    const normalStart = moment(moment(normalHourStart).format('hh:mm a'), 'hh:mm a')
    const normalEnd = moment(moment(normalHourEnd).format('hh:mm a'), 'hh:mm a')
    const afterStart = moment(moment(afterHourStart).format('hh:mm a'), 'hh:mm a')
    const afterEnd = moment(moment(afterHourEnd).format('hh:mm a'), 'hh:mm a')

    const isStartInNormalHour = moment(jobStartTime, 'hh:mm a').isBetween(normalStart, normalEnd, null, '[]');
    const isEndInNormalHour = moment(jobEndTime, 'hh:mm a').isBetween(normalStart, normalEnd, null, '[]');

    const isSameDay = startDate.isSame(endDate, 'day') || endDate.format('hh:mm:ssA') === '12:00:00AM'
    const isEndAtMidnight = endDate.format('hh:mm:ssA') === '12:00:00AM'
    const breakdowns = []

    /*
    console.log('rateSet', rateSet)
    console.log('jobStartTime', formatter.toStandardDate(jobStartTime), 'jobEndTime', formatter.toStandardDate(jobEndTime))
    console.log('normalStart', formatter.toStandardDate(normalStart), 'normalEnd', formatter.toStandardDate(normalEnd))
    console.log('isStartInNormalHour', isStartInNormalHour, 'isEndInNormalHour', isEndInNormalHour)
    */

    // return nothing if start date is later than end date
    if (!startDate.isBefore(endDate)) {
      return null
    }

    if (this.isSleepover) {
      console.log('Time Breakdown: [', '1 Unit S/O', ']')
      return {
        breakdown: '1 Unit S/O',
        hours: '1 Unit S/O'
      }
    } else if (isSameDay) {
      // 1. All in normal hours
      // 2. Start in normal hours, end in after hours
      // 3. Start in after hours, end in normal hours
      // 4. All in after hours

      if (isStartInNormalHour && isEndInNormalHour) {
        const duration = moment.duration(jobEndTime.diff(jobStartTime)).asHours()

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration,
          day: startDateDayNumber,
          isAfterHours: false,
          isHoliday: this.holiday
        })
      } else if (isStartInNormalHour && !isEndInNormalHour) {
        const normalDuration = moment.duration(normalEnd.diff(jobStartTime)).asHours()
        // amend on showing wrong breakdown when end time is 12:00AM
        const afterDuration = moment.duration(jobEndTime.diff(isEndAtMidnight ? afterStart.clone().subtract(1, 'day') : afterStart)).asHours()

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: normalDuration,
          day: startDateDayNumber,
          isAfterHours: false,
          isHoliday: this.holiday
        })

        if (afterDuration > 0) {
          breakdowns.push({
            start: jobStartTime,
            end: jobEndTime,
            duration: afterDuration,
            day: startDateDayNumber,
            isAfterHours: true,
            isHoliday: this.holiday
          })
        }
      } else if (!isStartInNormalHour && isEndInNormalHour) {
        const afterDuration = moment.duration(afterEnd.diff(jobStartTime)).asHours()
        const normalDuration = moment.duration(jobEndTime.diff(normalStart)).asHours()

        if (normalDuration > 0) {
          breakdowns.push({
            start: jobStartTime,
            end: jobEndTime,
            duration: normalDuration,
            day: startDateDayNumber,
            isAfterHours: false,
            isHoliday: this.holiday
          })
        }

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: afterDuration,
          day: startDateDayNumber,
          isAfterHours: true,
          isHoliday: this.holiday
        })
      } else {
        let duration = moment.duration(jobEndTime.diff(jobStartTime)).asHours()

        if (moment(endDate).format('hh:mm:ssA') === '12:00:00AM') {
          const midnightEnd = moment(startDate.clone().endOf('day').format('hh:mm:ss a'), 'hh:mm:ss a')
          duration = moment.duration(Math.abs(midnightEnd.diff(jobStartTime))).asHours().toFixed(2)
          duration = Number(duration)
        }

        const afterDuration = moment.duration(afterEnd.diff(jobStartTime)).asHours()
        const preNormalDurations = moment.duration(jobEndTime.diff(isEndAtMidnight ? afterStart.clone().subtract(1, 'day') : afterStart)).asHours()
        const normalDuration = moment.duration(normalEnd.diff(normalStart)).asHours()
        duration = afterDuration + preNormalDurations

        if (normalDuration > 0) {
          breakdowns.push({
            start: jobStartTime,
            end: jobEndTime,
            duration: normalDuration,
            day: startDateDayNumber,
            isAfterHours: false,
            isHoliday: this.holiday
          })
        }

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration,
          day: startDateDayNumber,
          isAfterHours: true,
          isHoliday: this.holiday
        })
      }
    } else {
      const midnightEnd = moment(moment().endOf('day').format('hh:mm:ss a'), 'hh:mm:ss a')
      const midnightStart = moment(moment().startOf('day').format('hh:mm a'), 'hh:mm a')

      if (isStartInNormalHour) {
        // First day - normal hours
        const normalDuration = moment.duration(normalEnd.diff(jobStartTime)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: normalDuration,
          day: startDateDayNumber,
          isAfterHours: false,
          isHoliday: this.holiday
        })

        const afterDuration = moment.duration(midnightEnd.diff(normalEnd)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: Math.round(afterDuration * 100) / 100,
          day: startDateDayNumber,
          isAfterHours: true,
          isHoliday: this.holiday
        })
      } else {
        // First day - after hours
        const afterDuration = moment.duration(midnightEnd.diff(jobStartTime)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: Math.round(afterDuration * 100) / 100,
          day: startDateDayNumber,
          isAfterHours: true,
          isHoliday: this.holiday
        })
      }

      // Second day
      const isEndInAfterHour = jobEndTime.isBetween(midnightStart, afterEnd)

      if (isEndInAfterHour) {
        // Second day - after hours
        const nextAfterDuration = moment.duration(jobEndTime.diff(midnightStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextAfterDuration,
          day: endDateDayNumber,
          isAfterHours: true,
          isHoliday: this.nextHoliday
        })
      } else {
        // Second day - after hours
        const nextAfterDuration = moment.duration(afterEnd.diff(midnightStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextAfterDuration,
          day: endDateDayNumber,
          isAfterHours: true,
          isHoliday: this.nextHoliday
        })
        // Second day - normal hours
        const nextNormalDuration = moment.duration(jobEndTime.diff(normalStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextNormalDuration,
          day: endDateDayNumber,
          isAfterHours: false,
          isHoliday: this.nextHoliday
        })
      }
    }
    return this.output(breakdowns)
  }

  output (breakdowns) {
    let breakdown = ''
    let hours = ''
    let currentDay = ''

    for (let i = 0; i < breakdowns.length; i++) {
      const { start, end, duration, day, isAfterHours, isHoliday } = breakdowns[i]

      if (i === 0) {
        breakdown = `${start.format('h:mmA')} - ${end.format('h:mmA')} (`
      }

      if (duration > 0) {
        const dts = `${formatter.toDecimal(duration)}H ${isHoliday ? 'P/H' : day < 6 ? 'Weekday' : day === 6 ? 'Sat' : 'Sun'}${isAfterHours ? ' A/H, ' : ', '}`
        hours += dts
        breakdown += dts
      }

      currentDay = day
    }

    breakdown = breakdown.substr(0, breakdown.length - 2) // + `)`
    hours = hours.substr(0, hours.length - 2)

    if (breakdown.indexOf('(') > 0) {
      breakdown += `)`
    }

    if (hours.indexOf('(') > 0) {
      hours = hours.replace(/\(/g, '')
    }

    console.log('Time Breakdown: [', breakdown, ']')

    return { breakdown, hours }
  }

  getDayInNumber (dateDay) {
    return (dateDay === 'Monday') ? 1 : (dateDay === 'Tuesday') ? 2 : (dateDay === 'Wednesday') ? 3 : (dateDay === 'Thursday') ? 4 : (dateDay === 'Friday') ? 5 : (dateDay === 'Saturday') ? 6 : 7
  }
}
