import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ExportType, ReportMenu } from '../../../constants'
import { authService, reportService } from '../../../services'
import { formatter, validator } from '../../../util'
import moment from 'moment'
// UI
import { ControlLabel, Page, Button } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
import { settingOtherService } from '../../../services/setting'
import { Input } from 'antd';
const { Item: FormItem } = Form
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const ActionAdd = 'add'
const ActionCancel = 'cancel'
const ActionUncancel = 'uncancel'
const ActionUpdate = 'update'
const ActionTotal = 'total'
const ActionPercentageTotal = '-per-total'
const ActionGrandTotal = '-per-grand-total'
const ActionPercentageAction = '-per-action'

export class ReportAction extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      info: {
        total_summary: { [ActionAdd]: 0, [ActionUpdate]: 0, [ActionCancel]: 0, [ActionTotal]: 0 },
        actions: [],
        summary: {}
      },
      dateFrom: moment().add(-1, 'day'),
      dateTo: moment().add(-1, 'day'),
      isDateRangeUpdated: false,
      loadingExport: false,
      settings: {},
    }
  }

  componentDidMount () {
    this.fetchList()
    this.fetchOtherSettings()
  }

  renderRow = (rows, total, type = '') => {
    const title = type === ActionPercentageAction
      ? ` (% OF TOTAL)`
      : type === ActionGrandTotal
        ? ` (% OF GRAND TOTAL)`
        : type === ActionPercentageTotal
          ? ` (% BY ACTION)`
          : ` (Count)`

    return (
      <table width='100%' style={{ marginTop: '40px' }} className='wd-list-container'>
        <thead className='wd-list-header'>
          <tr>
            <td width='25%'>
              Action{title}
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              New Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Amend Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Cancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Uncancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Total
            </td>
          </tr>
        </thead>
        <tbody>
          { rows.map((row, index) => {
            const role = row.role || ''
            const list = validator.isNotEmptyArray(row.list) ? row.list : []
            const summary = row.summary || {}

            return (
              <React.Fragment key={`sgmt${index}`}>
                <tr key={`header${index}`} className='wd-list-row'>
                  <td className='wd-list-col-full'>{role}</td>
                </tr>
                { list.map((rs, idx) => {
                  if (type) {
                    return (
                      <tr key={`content-${index}-${idx}`} className='wd-list-row'>
                        <td className='wd-list-col'><Link to={this.toReportUserDetail(rs.admin_code)}>{rs.admin}</Link></td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(rs[`${ActionAdd}${type}`] || 0)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(rs[`${ActionUpdate}${type}`] || 0)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(rs[`${ActionCancel}${type}`] || 0)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(rs[`${ActionUncancel}${type}`] || 0)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(rs[`${ActionTotal}${type}`] || 0)}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={`content-${index}-${idx}`} className='wd-list-row'>
                        <td className='wd-list-col'><Link to={this.toReportUserDetail(rs.admin_code)}>{rs.admin}</Link></td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{rs[`${ActionAdd}${type}`] || 0}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{rs[`${ActionUpdate}${type}`] || 0}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{rs[`${ActionCancel}${type}`] || 0}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{rs[`${ActionUncancel}${type}`] || 0}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{rs[`${ActionTotal}${type}`] || 0}</td>
                      </tr>
                    )
                  }
                })}
                { validator.isNotEmptyObject(summary)
                  ? ( type
                      ? <tr key={`summary-per-${index}`}>
                        <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(summary[`${ActionAdd}${type}`] || 0)}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(summary[`${ActionUpdate}${type}`] || 0)}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(summary[`${ActionCancel}${type}`] || 0)}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(summary[`${ActionUncancel}${type}`] || 0)}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(summary[`${ActionTotal}${type}`] || 0)}</td>
                      </tr>
                      : <tr key={`summary-cnt-${index}`}>
                        <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{summary[`${ActionAdd}${type}`] || 0}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{summary[`${ActionUpdate}${type}`] || 0}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{summary[`${ActionCancel}${type}`] || 0}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{summary[`${ActionUncancel}${type}`] || 0}</td>
                        <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{summary[`${ActionTotal}${type}`] || 0}</td>
                      </tr>)
                  : null
                }
              </React.Fragment>
            )
          })}
          { type
            ? <tr key={`total-per-${type}`}>
              <td className='wd-list-total'>&nbsp; Total</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(total[`${ActionAdd}${type}`] || 0)}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(total[`${ActionUpdate}${type}`] || 0)}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(total[`${ActionCancel}${type}`] || 0)}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(total[`${ActionUncancel}${type}`] || 0)}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentageFloat(total[`${ActionTotal}${type}`] || 0)}</td>
            </tr>
            : <tr key={`total-cnt-${type}`}>
              <td className='wd-list-total'>&nbsp; System Total</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{total[`${ActionAdd}${type}`] || 0}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{total[`${ActionUpdate}${type}`] || 0}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{total[`${ActionCancel}${type}`] || 0}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{total[`${ActionUncancel}${type}`] || 0}</td>
              <td className='wd-list-total' style={{ textAlign: 'center' }}>{total[`${ActionTotal}${type}`] || 0}</td>
            </tr>}
        </tbody>
      </table>
    )
}

  render () {
    const { info, dateFrom, dateTo, loading,loadingExport, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 10, md: 10, lg: 14 }
    }


    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='System User Actions'>
          { !loading
              ? this.hasAccess('listRecipients') 
              ? this.hasAccess('updateRecipient') ?
              <Button ghost key={'save'} feedback={loadingExport} onClick={() => this.handleSave()}>
                Save
              </Button> : null : null
              : null }
          </Page.Header>

          <div className='admin-list'>
            { !loading
              ? this.hasAccess('listRecipients') 
              ? <Form {...longFormItemLayout}>
                   <FormItem label="Weekly Email Recipients">
                          {getFieldDecorator('user_action_email', {
                          initialValue: settings.user_action_email,
                          rules: [{ required: true, message: 'Please enter weekly email report recipients' }]
                            })
                          (<Input />)
                          }
                   </FormItem>
                 </Form> : null
              : null }   
          </div>

          <div className='admin-list'>
            <ControlLabel>Date Range</ControlLabel>
            <RangePicker format="DD/MM/YYYY" onChange={(d1, d2) => this.onUpdateRange(d1, d2)} defaultValue={[dateFrom, dateTo]}/>

            <Skeleton loading={loading} active>
              {/** total count header */}
              <table width='100%' style={{ marginTop: '20px' }} cellPadding='2' cellSpacing='2'>
                <tbody>
                  <tr>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionAdd}`]}</div> New Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionUpdate}`]}</div> Amend Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionCancel}`]}</div> Cancel Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{info.total_summary[`${ActionUncancel}`]}</div> Uncancel Job
                    </td>
                  </tr>
                </tbody>
              </table>

              {/** actions */}
              { this.renderRow(info.actions, info.summary) }
              { this.renderRow(info.actions, info.summary, ActionPercentageTotal) }
              { this.renderRow(info.actions, info.summary, ActionGrandTotal) }
              { this.renderRow(info.actions, info.summary, ActionPercentageAction) }

            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onUpdateRange (d1, d2) {
    if (validator.isNotEmptyArray(d1)) {
      this.setState({ isDateRangeUpdated: true, dateFrom: d1[0], dateTo: d1[1] }, () => {
        this.fetchList()
      })
    }
  }

  fetchList () {
    const { dateFrom, dateTo } = this.state
    this.fetchActionReport({ dateFrom, dateTo })
  }

  async fetchActionReport ({ dateFrom, dateTo }) {
    const { loading } = this.state
    if (loading || !this.hasAccess('readActionReport')) return

    this.setState({ loading: true })

    const from = dateFrom.format(dateFormat)
    const to = dateTo.format(dateFormat)

    const info = await reportService.getListByDate(ExportType.REPORT.USER_ACTION, from, to)

    this.setState({ info, loading: false })
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })
          
          if (response.id) {
            notify.success('Saved successfully', `Settings saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save settings successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  toReportUserDetail = (adminCode) => {
    const { isDateRangeUpdated, dateFrom, dateTo } = this.state
    const q = isDateRangeUpdated ? `dateFrom=${formatter.toDate(dateFrom, dateFormat)}&dateTo=${formatter.toDate(dateTo, dateFormat)}` : ''
    return `/reports/action/${adminCode}${q ? `?${q}` : ''}`
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

export default Form.create()(ReportAction)
